.wrapper {
	.ee-acc-exclu-list {
		padding: 10px;

		.button-last-updated {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.last-report {
				font-size: 12px;
				font-weight: 400;
				font-style: italic;
				padding-right: 10px;
			}
		}

		.loading-wrapper,
		.no-data-wrapper,
		.error-wrapper {
			display: grid;
			place-content: center;
			height: calc(100vh - 220px);
		}

		.table-wrapper {
			display: flex;
			justify-content: center;

			.table {
				height: calc(100vh - 220px);
				margin-top: 10px;
				min-width: 700px;
			}
		}
	}
}

.actions {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
}
