.wrapper {
	width: min(100% - 32px, 1200px);
	margin-inline: auto;
	padding: 20px;
	background-color: #fff;
	height: 100vh;

	.loading-wrapper,
	.error-wrapper {
		display: grid;
		place-content: center;
		height: 100%;
	}

	.title {
		font-size: 26px;
		margin-bottom: 20px;
	}

	.subtitle {
		font-size: 14px;
		max-width: 800px;
		line-height: 20px;
		margin-bottom: 20px;
	}

	.table-wrapper {
		margin-top: 20px;
		height: calc(100vh - 260px);
	}
}
