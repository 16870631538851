.wrapper {
	.chipfinder-octapart-sources {
		padding: 10px;

		.loading-wrapper,
		.no-data-wrapper,
		.error-wrapper {
			display: grid;
			place-content: center;
			height: calc(100vh - 220px);
		}

		.table-wrapper {
			// display: flex;
			// justify-content: center;

			.table {
				height: calc(100vh - 220px);
				margin-top: 10px;
			}
		}
	}
}
