.wrapper {
	border-radius: 5px 0 0 0;
	border: 1px solid rgb(216, 216, 216);
	height: 100%;
	overflow-y: auto;
	padding: 10px 0 10px 10px;
	box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px,
		rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;

	.mass-mail-form {
		.to {
			border: 1px solid rgb(216, 216, 216);
			padding: 5px 30px;
			border-radius: 5px;
		}

		.jodit-container {
			:global {
				.jodit-container {
					border: 0;

					ul {
						display: block;
						list-style-type: disc;
						margin-block-start: 1em;
						margin-block-end: 1em;
						margin-inline-start: 0px;
						margin-inline-end: 0px;
						padding-inline-start: 40px;
						unicode-bidi: isolate;
					}

					ol {
						display: block;
						list-style-type: decimal;
						margin-block-start: 1em;
						margin-block-end: 1em;
						margin-inline-start: 0px;
						margin-inline-end: 0px;
						padding-inline-start: 40px;
						unicode-bidi: isolate;
					}

					a:-webkit-any-link {
						color: -webkit-link;
						cursor: pointer;
						text-decoration: underline;
					}
				}
				.jodit_disabled {
					background: #fff;
				}
			}
		}

		.email-body {
			:global {
				.ql-toolbar.ql-snow {
					border: none !important;
				}

				.ql-container.ql-snow {
					border: none !important;
				}
			}
		}

		.signature-body {
			:global {
				.ql-container.ql-snow {
					border: none !important;
				}
			}
		}
	}
}
