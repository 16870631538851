@import '../../sass/variables';

.cascader-wrapper {
	display: flex;
	align-items: center;
	border: 1px solid #d8d8d8;

	.title {
		font-size: 14px;
		font-weight: 600;
		padding: 9px 10px;
		background-color: $light-gray;

		@media (max-width: 768px) {
			font-size: 12px;
		}
	}

	.cascader {
		:global {
			.ant-select-selector {
				border: none !important;
				border-radius: 2px;
				background-color: $light-gray !important;

				&:focus {
					border: none !important;
				}

				.ant-select-selection-item {
					@media (max-width: 768px) {
						font-size: 12px;
					}
				}

				.ant-select-selection-placeholder {
					@media (max-width: 768px) {
						font-size: 12px;
					}
				}
			}
		}

		.suffixicon {
			font-size: 14px;
			color: $color-black;
			cursor: pointer;

			@media (max-width: 768px) {
				font-size: 12px;
			}
		}
	}
}

.menu {
	background-color: $light-gray;
	border-radius: 2px;
	box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px,
		rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
	z-index: 30;

	@media (max-width: 768px) {
		font-size: 12px;
	}
}

.expandicon {
	font-size: 14px;
	color: $color-black;
	margin-top: 6px;

	@media (max-width: 768px) {
		font-size: 12px;
	}
}
