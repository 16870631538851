@import '../../../sass/variables';

.loading-wrapper,
.error-wrapper,
.no-data-wrapper {
	display: grid;
	place-content: center;
	height: calc(100vh - 50px);
}

.customer-contact-engagement {
	.reports {
		display: flex;
		align-items: center;
		gap: 5px;

		.last-report {
			font-size: 12px;
			font-weight: 400;
			font-style: italic;
			padding-right: 10px;
		}
	}

	.wrapper {
		padding: 10px;
		background-color: $color-white;

		.dropdown-wrapper {
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			gap: 10px;
		}

		.numbers-info {
			display: flex;
			align-items: center;
			gap: 32px;
			flex-wrap: wrap;

			.info-wrapper {
				display: flex;
				align-items: center;
				gap: 5px;

				.title {
					color: $color-black;
					font-size: 14px;
					margin-bottom: 0;
					font-weight: 600;
				}

				.value-tooltip {
					display: flex;
					align-items: center;
					gap: 5px;

					.title {
						text-decoration: underline dotted black;
						text-decoration-thickness: 1px;
						cursor: pointer;
					}

					.value {
						font-weight: 600;
						font-size: 14px;
					}

					.value-red {
						color: red;
					}

					.tooltip-icon {
						font-weight: 400;
						cursor: pointer;
					}
				}
			}
		}

		.tabs-wrapper {
			display: flex;
			align-items: center;
			gap: 30px;
			margin-bottom: 30px;

			.tabs {
				display: flex;
				align-items: center;
				gap: 5px;
				cursor: pointer;
				font-size: 14px;
				font-weight: 400;
				color: rgb(50, 49, 48);
				padding: 10px 8px;

				&:hover {
					color: rgb(32, 31, 30);
					background-color: rgb(243, 242, 241);
				}
			}

			.tab-active {
				font-size: 14px;
				font-weight: 600;
				color: #000000;
				border-bottom: 2px solid rgb(34, 102, 227);
			}
		}

		.button-table-wrapper {
			position: relative;

			.button {
				position: absolute;
				left: 5px;
				top: 4px;
				z-index: 1000;
			}

			.table-wrapper {
				height: calc(100vh - 150px);
			}
		}
	}
}
