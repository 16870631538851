.drawer-footer {
	display: flex;
	justify-content: center;
	gap: 10px;
	align-items: center;
	padding: 0 8px;
}

.collapse-wrapper {
	:global {
		.ant-collapse-header {
			padding: 0 !important;
		}

		.ant-collapse-content-box {
			padding: 0 !important;
		}
	}
}

.drawer-table-wrapper {
	height: 300px;
}
