@import url('https://fonts.googleapis.com/css2?family=Edu+NSW+ACT+Foundation:wght@400;500;600;700&display=swap');

@import './variables';
@import './reset';
@import './ant';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html body {
	width: 100% !important;
}

body::-webkit-scrollbar {
	background-color: $color-white;
	width: 0.2rem;
}

body::-webkit-scrollbar-thumb {
	background-color: $dark-color;
}

body {
	background-color: $color-body;
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.d-flex {
	display: flex;
}

.pl-1 {
	padding-left: 0.25rem;
}

.pr-1 {
	padding-right: 0.25rem;
}

.pl-2 {
	padding-left: 0.5rem;
}

.pr-2 {
	padding-right: 0.5rem;
}

.pl-3 {
	padding-left: 1rem;
}

.pr-3 {
	padding-right: 1rem;
}

.pb-1 {
	padding-bottom: 0.25rem;
}

.pt-1 {
	padding-top: 0.25rem;
}

.pb-2 {
	padding-bottom: 0.5rem;
}

.pt-2 {
	padding-top: 0.5rem;
}

.pb-3 {
	padding-bottom: 1rem;
}

.pt-3 {
	padding-top: 1rem;
}

.ml-1 {
	margin-left: 0.25rem;
}

.mr-1 {
	margin-right: 0.25rem;
}

.ml-2 {
	margin-left: 0.5rem;
}

.mr-2 {
	margin-right: 0.5rem;
}

.ml-3 {
	margin-left: 1rem;
}

.mr-3 {
	margin-right: 1rem;
}

.mb-1 {
	margin-bottom: 0.25rem;
}

.mt-1 {
	margin-top: 0.25rem;
}

.mb-2 {
	margin-bottom: 0.5rem;
}

.mt-2 {
	margin-top: 0.5rem;
}

.mb-3 {
	margin-bottom: 1rem;
}

.mt-3 {
	margin-top: 1rem;
}

.font-weight-600 {
	font-weight: 600;
}

.font-12 {
	font-size: 12px;
}

.font-13 {
	font-size: 13px;
}

.font-14 {
	font-size: 14px;
}

.font-15 {
	font-size: 15px;
}

.font-16 {
	font-size: 16px;
}

.font-18 {
	font-size: 18px;
}

.text-light {
	color: #323232b3;
}

.cursor-pointer {
	cursor: pointer;
}
