.wrapper {
	.mail-storage {
		padding: 10px;

		.loading-wrapper,
		.no-data-wrapper,
		.error-wrapper {
			display: grid;
			place-content: center;
			height: calc(100vh - 200px);
		}

		.table-wrapper {
			height: calc(100vh - 200px);
			margin-top: 10px;
		}
	}
}
