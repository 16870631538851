.drawer-footer {
	display: flex;
	justify-content: center;
	gap: 10px;
	align-items: center;
	padding: 0 8px;
}

.file-upload {
	.file-upload-icon {
		font-size: 48px;
		margin-bottom: 12px;
		color: #1677ff;
	}

	.upload-label {
		font-weight: 500;
		font-size: 14px;
		color: #000;
		margin-bottom: 8px;
	}

	.upload-desc {
		letter-spacing: 0.03em;
		font-weight: 500;
		color: rgba(0, 0, 0, 0.4);
		font-size: 10px;
	}
}

.collapse-wrapper {
	margin-top: 20px;
	width: 100%;

	:global {
		.ant-collapse-header {
			padding: 0 !important;
		}

		.ant-collapse-content-box {
			padding: 0 !important;
		}
	}

	.loading-wrapper,
	.no-data-wrapper,
	.error-wrapper {
		display: grid;
		place-content: center;
		height: 379px;
	}

	.input-searchButton {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 10px;

		.search-icon {
			font-size: 14px;
		}
	}

	.table-wrapper {
		height: 379px;
		margin-bottom: 10px;
	}
}
