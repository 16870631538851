.customer-prices {
	padding: 10px;

	.dropdown-wrapper {
		display: flex;
		gap: 5px;
		margin-bottom: 10px;

		.input-button-wrapper {
			display: flex;
			gap: 5px;
			flex-wrap: wrap;

			.search-icon {
				font-size: 14px;
			}
		}
	}

	.loading-wrapper,
	.no-data-wrapper,
	.error-wrapper {
		display: grid;
		place-content: center;
		height: calc(100vh - 215px);
	}

	.table-wrapper {
		position: relative;

		.collapse-button {
			position: absolute;
			top: 5px;
			left: 4px;
			z-index: 1000;
		}

		.table {
			height: calc(100vh - 220px);
			margin-top: 10px;
		}
	}
}

.green-header {
	background-color: #9dde8b !important;
}
