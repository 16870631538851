@import '../../sass/variables';

.sidebar-wrapper {
	overflow: auto;
	height: calc(100vh - 48px);
	z-index: 1000;

	.collapse-btn {
		cursor: pointer;
		position: fixed;
		top: 70px;
		left: 15px;
		z-index: 10;
		color: $dark-color;
	}

	.side {
		background-color: $light-gray;
		overflow: auto;
		position: fixed;
		left: 0;
		top: 100px;
		bottom: 0;
		padding-bottom: 50px;

		:global {
			.ant-menu-item {
				padding-left: 10px !important;
				border-radius: 0px !important;
			}

			.ant-menu-submenu {
				.ant-menu-submenu-title {
					padding-left: 10px !important;
					border-radius: 0px !important;
				}
			}

			.ant-menu-inline {
				.ant-menu-item {
					padding-left: 35px !important;
					border-radius: 0px !important;
					// border-left: 3px solid blue;
				}
			}

			.ant-menu-item-selected {
				background-color: $color-white;
				color: $color-black !important;
				border-left: 3px solid #0078d4;
				font-weight: 600;
				margin-inline: 0;
				margin-left: 3px;
			}

			.ant-menu-submenu-selected {
				.ant-menu-submenu-title {
					background-color: $color-white;
					color: $color-black !important;
					// border-left: 2px solid blue;
					// margin-inline: 0;
					// width: calc(100% - 0px) !important;

					&:hover {
						color: $color-white;
					}
				}
			}

			.ant-menu-vertical {
				.ant-menu-item-selected {
					background-color: transparent;
				}
			}
		}

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.sidebar-collapsed {
		width: 45px;
	}

	.sidebar-sm {
		width: 270px;
	}

	.sidebar {
		width: 235px;
	}
}

:global {
	.ant-menu-submenu {
		.ant-menu {
			border-radius: 2px !important;

			.ant-menu-item {
				border-radius: 0px !important;
			}

			.ant-menu-item-selected {
				// background-color: rgba(0, 0, 0, 0.06);
				color: black;
				// border-left: 3px solid blue;
				font-weight: 600;
			}
		}
	}
}
