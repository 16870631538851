@import '../../../sass/variables';

.error-wrapper {
	display: grid;
	place-content: center;
	height: 100vh;
}

.loading-wrapper {
	display: grid;
	place-content: center;
	height: 50vh;
}

.loading-wrapper-1 {
	display: grid;
	place-content: center;
	height: 100vh;
}

.layout-wrapper {
	background-color: $color-white;

	.header {
		position: sticky;
		top: 0;
		left: 0;
		z-index: 1000;
	}

	.layout {
		display: flex;
		width: 100vw;

		.sidebar-content {
			background-color: $light-gray;
			border-right: 1px solid rgba(5, 5, 5, 0.06);
		}

		.collapsed-sidebar {
			width: 45px;
		}

		.sidebar {
			width: 235px;
		}

		.sidebar-sm {
			width: 270px;
		}

		.cont {
			height: calc(100% - 64px);
			background-color: $color-white;
		}

		.collapsed-content {
			width: calc(100vw - 45px);

			@media (max-width: 768px) {
				width: calc(100vw - 50px);
			}
		}

		.content-sm {
			width: calc(100vw - 270px);
		}

		.content {
			width: calc(100vw - 235px);
		}
	}
}

.login-wrapper {
	.header {
		background-color: #fff;
		box-shadow: 0 3px 24px rgba(0, 0, 0, 0.1);
		display: flex;
		height: 64px;
		align-items: center;
		justify-content: center;
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1000;
		font-size: 30px;
		font-weight: 700;
	}

	.login {
		height: calc(100vh - 64px);
		display: grid;
		place-content: center;

		.sections {
			display: flex;

			.left-side {
				border-right: 2px solid #000;
				padding: 20px;
			}

			.right-side {
				padding: 20px;
				display: flex;
				align-items: center;

				.login-button {
					display: flex;
					align-items: center;
					cursor: pointer;
					gap: 10px;
					border: 1px solid #5e5e5e;
					background-color: #fff;
					padding: 10px 15px;

					.img {
						width: 20px;
					}

					.span {
						font-weight: 600;
						color: #5e5e5e;
					}
				}
			}
		}
	}
}
