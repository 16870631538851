.wrapper {
	background-color: #f7fbfb;

	.api-health-checker {
		padding: 10px;

		.loading-wrapper,
		.no-data-wrapper,
		.error-wrapper {
			display: grid;
			place-content: center;
			height: calc(100vh - 220px);
		}

		.data-wrapper {
			margin: 0 auto;
			max-width: 850px;

			.api-status-wrapper {
				border: 1px solid #e0e0e0;
				border-radius: 4px;

				.api-status-name {
					border-bottom: 1px solid #e0e0e0;
					padding: 1.1rem 1.25rem 1rem;

					&:last-child {
						border: none;
					}

					.name-tooltip-status {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding-bottom: 5px;

						.right-side {
							display: flex;
							align-items: center;
							gap: 5px;

							.name {
								font-size: 16px;
								font-weight: 509;
							}

							.tooltip-icon {
								font-size: 18px;
								cursor: pointer;
							}
						}

						.status {
							font-size: 14px;
							color: #10a37f;
						}

						.status-error {
							font-size: 14px;
							color: #ef4146;
						}
					}

					.logs-wrapper {
						display: flex;
						align-items: center;
						gap: 3px;
						margin: 5px 0;

						.log {
							height: 34px;
							width: 100%;
							background-color: #10a37f;
							cursor: pointer;

							&:hover {
								background-color: #5e6c84;
							}
						}

						.log-error {
							height: 34px;
							width: 100%;
							background-color: #ef4146;
							cursor: pointer;

							&:hover {
								background-color: #5e6c84;
							}
						}
					}

					.log-time {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 10px;

						.end-time,
						.current-time {
							font-size: 14px;
							color: #aaaaaa;
						}

						.end-time {
							width: 92px;
						}

						.divider {
							margin: 5px 0 0 0;
							min-width: 70%;
							display: flex;
							align-items: normal;

							&::after {
								border-block-start: 0.5px solid #aaaaaa;
							}

							&::before {
								border-block-start: 1px solid #aaaaaa;
							}
						}
					}
				}
			}
		}
	}
}

.table-wrapper {
	.table {
		height: 500px;
		margin-top: 10px;
	}
}
