.wrapper {
	background-color: #333;
	color: #fff;
	border-radius: 4px;
	padding: 5px 10px;
	display: flex;
	justify-content: space-between;
	gap: 15px;
	align-items: flex-start;
	transition: all 0.3s ease-in-out;

	&::after {
		content: '';
		position: absolute;
		top: 100%;
		right: 3%;
		margin-left: -5px;
		border-width: 7px;
		border-style: solid;
		border-color: #333 transparent transparent transparent;
	}

	.title {
		font-size: 12px;
		line-height: 1.4;
		max-width: 200px;
	}

	.close {
		font-size: 16px;
		cursor: pointer;
		color: #fff;
	}
}

.left-wrapper {
	background-color: #333;
	color: #fff;
	border-radius: 4px;
	padding: 5px 10px;
	display: flex;
	justify-content: space-between;
	gap: 15px;
	align-items: flex-start;
	transition: all 0.3s ease-in-out;

	&::after {
		content: '';
		position: absolute;
		top: 100%;
		left: 3%;
		margin-left: -5px;
		border-width: 7px;
		border-style: solid;
		border-color: #333 transparent transparent transparent;
	}

	.title {
		font-size: 12px;
		line-height: 1.4;
		max-width: 200px;
	}

	.close {
		font-size: 16px;
		cursor: pointer;
		color: #fff;
	}
}
