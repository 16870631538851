.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100vh;

	.message-wrapper {
		background-color: #fff2f0;
		border: 1px solid #ffccc7;
		border-radius: 8px;
		padding: 30px;
		text-align: center;

		.icon {
			font-size: 60px;
			color: #ff3333;
			margin-bottom: 20px;
		}

		.message {
			font-size: 20px;
			font-weight: 600;
		}
	}
}
