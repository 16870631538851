@import '../../../../sass/variables';

.actions {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	margin-right: 25px;
}

.drawer-header {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.button {
		position: relative;
		overflow: hidden;
		height: 2.5rem;
		padding: 0 1rem;
		border-radius: 5px;
		background: #3d3a4e;
		background-size: 400%;
		color: #fff;
		border: none;
		cursor: pointer;

		&:hover::before {
			transform: scaleX(1);
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			transform: scaleX(0);
			transform-origin: 0 50%;
			width: 100%;
			height: inherit;
			border-radius: inherit;
			background: linear-gradient(
				82.3deg,
				rgba(150, 93, 233, 1) 10.8%,
				rgba(99, 88, 238, 1) 94.3%
			);
			transition: all 0.475s;
		}

		.button-content {
			position: relative;
			z-index: 1;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 10px;
		}

		.container-stars {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
			transition: 0.5s;
			backdrop-filter: blur(1rem);
			border-radius: 5rem;
		}

		.stars {
			position: relative;
			background: transparent;
			width: 200rem;
			height: 200rem;
		}

		.stars::after {
			content: '';
			position: absolute;
			top: -10rem;
			left: -100rem;
			width: 100%;
			height: 100%;
			animation: animStarRotate 90s linear infinite;
		}

		.stars::before {
			content: '';
			position: absolute;
			top: 0;
			left: -50%;
			width: 170%;
			height: 500%;
			animation: animStar 60s linear infinite;
		}

		.stars::before {
			background-image: radial-gradient(#ffffff 1px, transparent 1%);
			background-size: 50px 50px;
			opacity: 0.5;
		}

		@keyframes animStar {
			from {
				transform: translateY(0);
			}

			to {
				transform: translateY(-135rem);
			}
		}

		@keyframes animStarRotate {
			from {
				transform: rotate(0deg);
			}

			to {
				transform: rotate(360deg);
			}
		}
	}
}

.form-wrapper {
	height: 100%;
	overflow: auto;

	&::-webkit-scrollbar {
		background-color: $color-white;
		width: 0.2rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #a9a9a9;
	}

	.form {
		padding: 0 10px 0 0;
	}
}

.flags {
	display: flex;
	align-items: center;
	gap: 10px;

	.flag {
		width: 20px;
	}
}
