.wrapper {
	.mass-mail {
		display: flex;
		height: calc(100vh - 120px);
		position: relative;

		.left-side {
			width: 50%;
			min-width: 25%;
			padding: 0 10px;
			overflow: auto;

			.input-checkbox {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				gap: 10px;
				margin: 10px 0;

				.dropdown-input {
					display: flex;
				}
			}

			.strengths-wrapper {
				p {
					font-size: 14px;
				}

				display: flex;
				align-items: center;
				gap: 10px;
			}

			.loading-wrapper,
			.no-data-wrapper,
			.error-wrapper {
				display: grid;
				place-content: center;
				height: calc(100vh - 220px);
			}

			.table-wrapper {
				.table {
					height: calc(100vh - 220px);
					margin-top: 10px;
				}
			}
		}

		.divider {
			width: 1px;
			background-color: rgb(216, 216, 216);
			cursor: ew-resize;
			position: relative;

			&::after {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 50%;
				width: 10px;
			}
		}

		.right-side {
			width: 50%;
			min-width: 25%;
			padding: 10px 0 0 10px;
			overflow-y: auto;
			height: 100%;
		}
	}
}
