.upload-bom-wrapper {
	margin-top: 10px;

	.point-1-wrapper {
		margin-bottom: 20px;

		.point1-title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 10px;

			.download-template {
				color: #0070c0;
				text-decoration: underline;
				font-weight: 600;
				cursor: pointer;
			}
		}
		.point-2-wrapper {
			margin-top: 10px;
			display: flex;
			align-items: flex-start;
			gap: 10px;
		}
	}
}
