@import '../../../sass/variables';

.link {
	cursor: pointer;
	color: #2266e3;
	font-weight: 600;
}

.customer-report-message {
	font-size: 12px;
	font-weight: 500;
	line-height: 17px;
}

.mpnReport-wrapper {
	.mpn-report {
		padding: 10px;
		background-color: $color-white;

		.loading-wrapper,
		.no-data-wrapper,
		.error-wrapper {
			display: grid;
			place-content: center;
			height: calc(100vh - 290px);
		}

		.dropdown-wrapper {
			display: flex;
			align-items: center;
			gap: 20px;
			margin-bottom: 10px;
			flex-wrap: wrap;
		}

		.table-wrapper {
			margin-top: 10px;
			height: calc(100vh - 140px);
			position: relative;
			.table-tooltip-notification {
				position: absolute;
				right: 0;
				z-index: 10;
			}
		}
	}
}
