@import '../../sass/variables';

.close-icon {
	font-size: 22px;
}

.modal-wrapper {
	:global {
		.ant-modal-content {
			border-radius: 2px !important;
			color: $color-black;
		}
	}

	// .content {
	// 	height: 100%;
	// 	overflow: auto;
	// }
}
