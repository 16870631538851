@import '../../sass/variables';

.home-wrapper {
	padding: 10px 20px;
	height: calc(100vh - 64px);
	display: grid;
	place-content: center;
	overflow: auto;

	.journey {
		.user-journey-wrapper {
			display: flex;
			align-items: center;
			gap: 10px;
			margin-bottom: 30px;

			.user-wrapper {
				display: flex;
				flex-direction: column;
				align-items: center;

				.user-avatar {
					width: 100px;
					height: 100px;
					border-radius: 50px;
					object-fit: cover;
				}

				.user-name {
					font-size: 16px;
					margin-top: 10px;
					text-align: center;
					color: #000000;
					margin-bottom: 0;
					line-height: 20px;
				}
			}

			.right-arrow {
				.arrow {
					font-size: 40px;
					font-weight: 700;
				}
			}

			.journey-card-wrapper {
				background-color: #ffffff;
				border: 1px solid rgba(0, 0, 0, 0.1);
				padding: 10px;
				height: 100%;
				min-height: 120px;
				border-radius: 3px;
				width: 100%;

				.journey-name {
					font-size: 14px;
					line-height: 20px;
					color: rgb(50, 49, 48);
					border-bottom: 1px solid rgba(0, 0, 0, 0.1);
					padding: 0 8px 10px 8px;
					font-weight: 600;
				}

				.cards-wrapper {
					display: flex;
					gap: 15px;
					padding-top: 10px;

					.disable-card {
						opacity: 0.5 !important;
						pointer-events: none;
					}

					.link-card {
						text-decoration: none;
						width: 100%;

						.journey-card {
							background-color: #faf8f9;
							color: #000000;
							width: 100%;
							height: 100%;
							box-shadow: rgba(0, 0, 0, 0.13) 0px 0.6px 5px;
							border-radius: 3px;
							padding: 10px;

							.title {
								font-size: 12px;
								/* font-weight: 600; */
								line-height: 20px;
								margin-bottom: 0;
								display: grid;
								place-content: center;
								height: 35px;
								text-align: center;
							}
						}

						.card-hover-border-sr {
							border: 2px solid $color-green;

							&:hover {
								background-color: $color-green;
								color: #ffffff;
							}
						}

						.card-hover-border-sm {
							border: 2px solid $color-blue;

							&:hover {
								background-color: $color-blue;
								color: #ffffff;
							}
						}

						.card-hover-border-ss {
							border: 2px solid $color-red;

							&:hover {
								background-color: $color-red;
								color: #ffffff;
							}
						}

						.card-hover-border-ceo {
							border: 2px solid $color-purple;

							&:hover {
								background-color: $color-purple;
								color: #ffffff;
							}
						}

						.card-hover-border {
							border: 2px solid $dark-color;

							&:hover {
								background-color: $dark-color;
								color: #ffffff;
							}
						}
					}
				}
			}
		}

		.customer-journey-wrapper {
			display: flex;
			gap: 70px;
			margin-bottom: 50px;
			width: 100%;

			.avatar-arrow-wrapper {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding-top: 25px;
				gap: 20px;

				.curve-arrow {
					margin-left: 20px;

					.arrow {
						font-size: 40px;
						font-weight: 700;
					}
				}

				.my-customer-avatar-name {
					display: flex;
					flex-direction: column;
					align-items: center;

					.avatar {
						width: 100px;
						height: 100px;
						border-radius: 50px;
						object-fit: cover;
					}

					.name {
						font-size: 16px;
						margin-top: 10px;
						text-align: center;
						color: #000000;
						margin-bottom: 0;
						line-height: 20px;
					}
				}
			}

			.cards-arrow-wrapper {
				width: 100%;

				.cards-arrow-wrapper-first-section {
					display: flex;
					align-items: center;
					gap: 15px;
					margin-bottom: 80px;

					.journey {
						background-color: #ffffff;
						padding: 10px;
						width: 100%;
						border: 1px solid rgba(0, 0, 0, 0.1);
						min-height: 120px;
						height: 100%;
						border-radius: 3px;

						.journey-name {
							font-size: 14px;
							line-height: 20px;
							color: rgb(50, 49, 48);
							border-bottom: 1px solid rgba(0, 0, 0, 0.1);
							padding: 0 8px 10px 8px;
							font-weight: 600;
						}

						.cards-wrapper {
							display: flex;
							gap: 15px;
							padding-top: 10px;

							.disable-card {
								opacity: 0.5 !important;
								pointer-events: none;
							}

							.link-card {
								text-decoration: none;
								width: 100%;

								.journey-card {
									background-color: #faf8f9;
									color: #000000;
									width: 100%;
									height: 100%;
									box-shadow: rgba(0, 0, 0, 0.13) 0px 0.6px 5px;
									border-radius: 3px;
									padding: 10px;

									.title {
										font-size: 12px;
										line-height: 20px;
										margin-bottom: 0;
										display: grid;
										place-content: center;
										height: 35px;
										text-align: center;
									}
								}

								.card-hover-border-sr {
									border: 2px solid $color-green;

									&:hover {
										background-color: $color-green;
										color: #ffffff;
									}
								}

								.card-hover-border-sm {
									border: 2px solid $color-blue;

									&:hover {
										background-color: $color-blue;
										color: #ffffff;
									}
								}

								.card-hover-border-ss {
									border: 2px solid $color-red;

									&:hover {
										background-color: $color-red;
										color: #ffffff;
									}
								}

								.card-hover-border-ceo {
									border: 2px solid $color-purple;

									&:hover {
										background-color: $color-purple;
										color: #ffffff;
									}
								}

								.card-hover-border {
									border: 2px solid $dark-color;

									&:hover {
										background-color: $dark-color;
										color: #ffffff;
									}
								}
							}
						}
					}

					.right-arrow {
						.arrow {
							font-size: 40px;
							font-weight: 700;
						}
					}

					.rfq-opportunities {
						position: relative;

						.down-arrow {
							display: flex;
							justify-content: center;

							.arr {
								font-size: 40px;
								font-weight: 700;
								position: absolute;
								top: 30px;
							}

							.arrow {
								font-size: 40px;
								font-weight: 700;
								position: absolute;
								top: 142px;
							}
						}
					}
				}

				.cards-arrow-wrapper-second-section {
					display: flex;
					align-items: center;
					gap: 15px;

					.journey {
						background-color: #ffffff;
						padding: 10px;
						width: 100%;
						border: 1px solid rgba(0, 0, 0, 0.1);
						min-width: 500px;
						min-height: 120px;
						height: 100%;
						border-radius: 3px;

						.journey-name {
							font-size: 14px;
							line-height: 20px;
							color: rgb(50, 49, 48);
							border-bottom: 1px solid rgba(0, 0, 0, 0.1);
							padding: 0 8px 10px 8px;
							font-weight: 600;
						}

						.cards-wrapper {
							display: flex;
							gap: 15px;
							padding-top: 10px;

							.disable-card {
								opacity: 0.5 !important;
								pointer-events: none;
							}

							.link-card {
								text-decoration: none;
								width: 100%;

								.journey-card {
									background-color: #faf8f9;
									color: #000000;
									width: 100%;
									height: 100%;
									box-shadow: rgba(0, 0, 0, 0.13) 0px 0.6px 5px;
									border-radius: 3px;
									padding: 10px;

									.title {
										font-size: 12px;
										line-height: 20px;
										margin-bottom: 0;
										display: grid;
										place-content: center;
										height: 35px;
										text-align: center;
									}
								}

								.card-hover-border-sr {
									border: 2px solid $color-green;

									&:hover {
										background-color: $color-green;
										color: #ffffff;
									}
								}

								.card-hover-border-sm {
									border: 2px solid $color-blue;

									&:hover {
										background-color: $color-blue;
										color: #ffffff;
									}
								}

								.card-hover-border-ceo {
									border: 2px solid $color-purple;

									&:hover {
										background-color: $color-purple;
										color: #ffffff;
									}
								}

								.card-hover-border {
									border: 2px solid $dark-color;

									&:hover {
										background-color: $dark-color;
										color: #ffffff;
									}
								}

								.card-hover-border-sr {
									border: 2px solid $color-green;

									&:hover {
										background-color: $color-green;
										color: #ffffff;
									}
								}

								.card-hover-border-sm {
									border: 2px solid $color-blue;

									&:hover {
										background-color: $color-blue;
										color: #ffffff;
									}
								}

								.card-hover-border-ss {
									border: 2px solid $color-red;

									&:hover {
										background-color: $color-red;
										color: #ffffff;
									}
								}

								.card-hover-border-ceo {
									border: 2px solid $color-purple;

									&:hover {
										background-color: $color-purple;
										color: #ffffff;
									}
								}

								.card-hover-border {
									border: 2px solid $dark-color;

									&:hover {
										background-color: $dark-color;
										color: #ffffff;
									}
								}
							}
						}
					}

					.left-arrow {
						.arrow {
							font-size: 40px;
							font-weight: 700;
						}
					}
				}
			}
		}
	}
}
