@import '../../sass/variables';

.inner-header-wrapper {
	border-bottom: 1px solid rgb(216, 216, 216);
	border-top: 1px solid rgb(216, 216, 216);
	background-color: $color-white;

	.inner-header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.left-section {
			display: flex;
			align-items: center;

			.back-icon {
				padding: 10px 12px;
				border-right: 1px solid rgb(216, 216, 216);
				cursor: pointer;
			}

			.export-btn {
				all: unset;
				padding: 10px 12px;
				cursor: pointer;
				display: flex;
				align-items: center;
				gap: 10px;
				font-size: 13px;
				font-weight: 400;

				&:hover {
					background-color: lightgray;
				}

				.export-image {
					width: 18px;

					@media (max-width: 768px) {
						width: 14px;
					}
				}

				span {
					@media (max-width: 768px) {
						display: none;
					}
				}
			}

			.export-montly {
				pointer-events: none;
				opacity: 0.4;
			}
		}

		.right-section {
			display: flex;
			align-items: center;
			gap: 5px;

			.last-report {
				font-size: 12px;
				font-weight: 400;
				font-style: italic;
				padding-right: 10px;

				@media (max-width: 768px) {
					font-size: 10px;
				}
			}

			.update-page {
				display: flex;
				align-items: center;
				gap: 5px;
				cursor: pointer;
				padding: 11px 12px;

				&:hover {
					background-color: lightgray;
				}

				.update-icon {
					color: $microsoft-primary-color;
				}

				.update {
					font-size: 12px;
					font-weight: 400;

					@media (max-width: 768px) {
						font-size: 10px;
					}
				}
			}

			.disable-update-page {
				opacity: 0.3;
				pointer-events: none;
			}
		}
	}
}
