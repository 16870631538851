.notification-bar {
	color: #000000;
	padding: 10px;
	margin-bottom: 10px;

	.content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.notification {
			display: flex;
			gap: 10px;
			align-items: center;
			width: 100%;

			.text {
				font-weight: 500;
				font-size: 14px;
				line-height: 14px;

				@media (max-width: 768px) {
					font-size: 12px;
				}
			}
		}
		.close {
			font-size: 18px;
			cursor: pointer;
		}
	}
}

.light-blue {
	background-color: #e6f4ff;
	border: 1px solid #91caff;
}

.bg-yellow {
	background-color: #fffbe6;
	border: 1px solid #ffe58f;
}

.bg-red {
	background-color: #fff2f0;
	border: 1px solid #ffccc7;
}

.bg-green {
	background-color: #f6ffed;
	border: 1px solid #b7eb8f;
}
