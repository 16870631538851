.table {
	width: 100%;
	border-collapse: collapse;

	.th,
	.td {
		border: 1px solid #ddddde;
		padding: 4px 8px;
		text-align: left;
	}

	.th {
		background-color: #fafbfb;
	}
}
