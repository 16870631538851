.wrapper {
	.management-report {
		padding: 10px;

		.dropdown-wrapper {
			display: flex;
			align-items: center;
			gap: 20px;
			margin-bottom: 10px;
			flex-wrap: wrap;
		}

		.loading-wrapper,
		.no-data-wrapper,
		.error-wrapper {
			display: grid;
			place-content: center;
			height: calc(100vh - 70px);
		}

		.table-wrapper {
			.table {
				height: calc(100vh - 200px);
				margin-top: 10px;
			}
		}
	}
}

.ul-mr {
	margin-top: 10px;
	list-style: disc;
	padding-left: 20px;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
	gap: 2px;

	.li-mr {
		cursor: pointer;
		padding: 3px 5px;
		width: fit-content;
		border-radius: 2px;
		&:hover {
			background-color: #dedcdc;
		}
	}
}

.youtube {
	all: unset;
	padding: 10px 12px;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 13px;
	font-weight: 400;

	&:hover {
		background-color: lightgray;
	}

	.youtube-icon {
		font-size: 18px;
		color: #ff0000;
	}
}
