.wrapper {
	.data-wrapper {
		display: flex;
		gap: 3px;
		align-items: center;

		.edit-icon {
			font-size: 14px;
			cursor: pointer;
			margin-right: 3px;
		}
	}

	.blue-underline {
		color: #0070c0;
		text-decoration: underline;
		cursor: pointer;
		font-size: 12px;
	}
}
