.popconfirm-title {
	font-weight: 600;
	font-size: 16px;
}

.popconfirm-description {
	width: 200px;
	font-weight: 500;
	font-size: 12px;
	text-align: justify;
	line-height: 17px;
}

.footer-buttons {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
}

:global {
	.ant-popconfirm-buttons {
		display: none;
	}
}
