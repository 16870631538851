.wrapper {
	.learn-centre {
		padding: 20px;
		display: grid;
		place-content: center;

		.cards-wrapper {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(355px, 1fr));
			grid-gap: 20px;
			gap: 20px;
			max-width: 1110px;

			@media (max-width: 1080px) {
				max-width: 100%;
			}
		}

		// .table-wrapper {
		// 	display: flex;
		// 	justify-content: center;

		// 	.table {
		// 		height: calc(100vh - 220px);
		// 		margin-top: 10px;
		// 		min-width: 800px;
		// 	}
		// }
	}
}

.card-wrapper {
	background: #fff;
	border-radius: 8px;
	max-width: 355px;
	min-height: 256px;
	height: 100%;
	border: 1px solid #f0f0f0;
	transition: all 0.3s ease-in-out;

	&:hover {
		box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
	}

	.image {
		object-fit: cover;
		width: 100%;
		height: 200px;
		border-radius: 8px 8px 0 0;
	}

	.contents {
		padding: 24px;

		.title {
			font-size: 22px;
			margin-bottom: 15px;
			font-weight: 600;
		}

		.links {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			gap: 15px;

			.link {
				font-size: 14px;
				display: flex;
				align-items: center;
				gap: 10px;
				text-decoration: none;
			}
		}
	}
}
