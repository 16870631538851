.wrapper {
	padding: 10px;
	height: 100vh;

	.loading-wrapper,
	.error-wrapper {
		height: 100%;
		display: grid;
		place-content: center;
	}

	.table-wrapper {
		margin-top: 20px;
		height: calc(100vh - 80px);
	}
}
