@import '../../sass/variables';

.wrapper {
	display: flex;
	align-items: center;
	border: 1px solid #d8d8d8;

	.title {
		font-size: 14px;
		font-weight: 600;
		background-color: $light-gray;
		padding: 9px 0px 9px 10px;
	}

	.select {
		:global {
			.ant-select-selector {
				border: none !important;
				border-radius: 2px;
				background-color: $light-gray !important;

				&:focus {
					border: none !important;
				}
			}
		}

		.suffixicon {
			font-size: 14px;
			color: $color-black;
		}
	}
}

.border-select {
	:global {
		.ant-select-selector {
			// border: 1px solid #d9d9d9 !important;
			border-radius: 2px;
			// background-color: transparent !important;
		}
	}

	.suffixicon {
		font-size: 14px;
		color: $color-black;
	}
}

.menu {
	background-color: $light-gray;
	border-radius: 2px;
	box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px,
		rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
}

.border-menu {
	background-color: $color-white;
	border-radius: 2px;
	box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px,
		rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
}
