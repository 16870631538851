@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.ag-tooltip {
	background-color: #333;
	color: #fff;
	border-radius: 4px;
	font-size: 12px;
	line-height: 1.4;
	white-space: normal;
	padding: 5px 10px;
}

.ag-cell-wrapper.ag-row-group {
	align-items: center;
}

.ag-watermark {
	bottom: 20px;
	opacity: 0 !important;
	position: absolute;
	right: 25px;
	transition: opacity 1s ease-out 3s;
}

.red-row {
	background-color: #ff9b9b;
	color: #331d2c;
}

.ag-header-group-cell-with-group {
	font-weight: 700;
	color: #000;
}

.ag-header-cell {
	font-weight: 700;
	color: #000;
}

.ag-paging-panel,
.ag-theme-balham .ag-column-drop-empty,
.ag-column-drop-horizontal-empty-message {
	color: #000;
}

@media (max-width: 768px) {
	.ag-paging-panel,
	.ag-theme-balham .ag-column-drop-empty,
	.ag-column-drop-horizontal-empty-message {
		color: #000;
		font-size: 10px;
	}
}

/* .ag-rich-select {
	overflow: hidden;
	width: 100px;
	height: 92px;
} */

.ag-theme-balham .ag-row-group {
	font-weight: 500;
}

.ag-sort-order {
	display: none !important;
}

/* .ag-rich-select-value {
	display: none;
} */

.ag-header-group-cell-label {
	justify-content: center;
}

.cell-error-bg {
	background-color: #ffeded !important;
}
