@import '../../../../sass/variables';

.wrapper {
	.icons {
		display: flex;
		align-items: center;
		gap: 5px;
		font-size: 16px;
	}
}

.franchised-details-wrapper {
	height: 80vh;
	overflow: auto;
	padding: 0 5px 0 0;

	&::-webkit-scrollbar {
		background-color: $color-white;
		width: 0.2rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #a9a9a9;
	}

	.banner-image {
		width: 100%;
		height: 250px;
		object-fit: cover;
		border-radius: 5px;
	}

	.upper-section {
		display: flex;
		justify-content: space-between;

		.logo-name-wrapper {
			display: flex;
			gap: 20px;

			.logo-wrapper {
				margin-top: -50px;
				margin-left: 30px;
				width: 115px;
				height: 115px;
				background-color: #fff;
				border-radius: 5px;
				box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
				padding: 5px;
				border: 1px solid #f0f0f0;
				display: grid;
				place-items: center;
				z-index: 10;
				position: relative;

				.logo {
					width: 100px;
					height: 100px;
					object-fit: contain;
					border-radius: 5px;
				}
			}
			.company-name-link {
				display: flex;
				align-items: center;
				gap: 10px;

				.company-name {
					font-size: 36px;
					font-weight: 600;
				}

				.link {
					margin-top: 7px;
					font-size: 20px;
				}
			}
		}

		.files-wrapper {
			display: flex;
			justify-content: space-between;
			gap: 10px;
			margin-top: 15px;

			.link {
				display: flex;
				align-items: center;
				gap: 5px;
				background: #fff;
				border-radius: 4px;
				border: 1px solid #f0f0f0;
				transition: all 0.3s ease-in-out;
				padding: 10px;
				height: 40px;
				font-weight: 500;

				&:hover {
					box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);

					.icon {
						color: #00308f;
					}

					span {
						color: #00308f;
					}
				}
			}
		}
	}

	.bottom-section {
		margin-top: 20px;

		.description-wrapper {
			margin-bottom: 10px;
			display: flex;
			gap: 10px;

			.title {
				font-size: 18px;
				font-weight: 600;
				min-width: 150px;
			}

			.description {
				font-size: 14px;
			}
		}
	}
}

.modal-header-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-right: 20px;

	.right-side {
		display: flex;
		align-items: center;
		gap: 5px;

		.flag {
			background-color: #f0f0f0;
			padding: 3px 10px;
			border-radius: 5px;
			border: 1px solid #a9a9a9;
			display: flex;
			align-items: center;
			gap: 5px;

			span {
				font-size: 12px;
			}
		}
	}
}
