.content {
	.text {
		font-size: 16px;
		line-height: 20px;
		text-align: justify;

		&:first-child {
			padding-bottom: 20px;
		}
	}

	.info-wrapper {
		display: flex;
		flex-direction: column;
		gap: 15px;

		.info {
			display: flex;
			gap: 10px;
			font-size: 16px;

			.name {
				font-weight: 600;
				width: 130px;
			}

			.content {
				width: 190px;
			}
		}
	}
}

.footer {
	display: flex;
	justify-content: space-between;
}
