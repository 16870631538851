.font-weight {
	font-weight: 400;
}

.ee-font-weight {
	font-weight: 600;
	color: #1677ff;
}

.link {
	color: #1677ff;
}

.mail {
	color: #fdb441;
	cursor: pointer;
	font-size: 16px;
}

.chipfinder-status {
	display: flex;
	align-items: center;
	gap: 5px;

	.chipfinder-progress {
		margin-bottom: 0;
		margin-inline-end: 0;
	}

	.sheet {
		width: 20px;
		cursor: pointer;
	}

	.chipfinder-tooltip-status {
		font-size: 18px;
		cursor: pointer;
	}
}

.gray-cell {
	background-color: #f8f8f8;
}

.blue-header {
	background-color: #dae7f2;
}

.pink-header {
	background-color: #f998c0;
}

.dark-blue-header {
	background-color: #1479c0;
	color: #fff !important;

	&:hover {
		color: #000 !important;
	}
}

.value {
	font-weight: 400;
}
