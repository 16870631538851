.breadcrumb-wrapper {
	margin: 10px 0 10px 10px;

	.breadcrumb-item {
		font-size: 14px;
		font-weight: 600;
		color: #000;
		display: flex;
		align-items: center;
		gap: 5px;

		.icon {
			font-size: 18px;
		}

		@media (max-width: 768px) {
			font-size: 12px;
		}
	}

	.breadcrumb-item-2 {
		font-size: 14px;
		// font-weight: 400;
		color: #000;

		@media (max-width: 768px) {
			font-size: 12px;
		}
	}
}
