.title-wrapper {
	display: flex;
	justify-content: space-between;

	.close-icons {
		font-size: 20px;
		cursor: pointer;
	}
}

.body-wrapper {
	height: 600px;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		background-color: #fff;
		width: 0.2rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #001433;
	}

	.dropdown-wrapper {
		margin-top: 20px;

		.form-item {
			margin-bottom: 20px;
			width: 100%;

			.title {
				font-size: 14px;
				font-weight: 500;
				padding-bottom: 10px;
			}

			.file-format {
				font-size: 12px;
				font-weight: 400;
				text-decoration-line: underline;
			}
		}
	}

	.tabs-wrapper {
		.please-wait {
			font-size: 12px;
		}
		.steps-content {
			margin-top: 10px;
			padding: 20px 20px 0 20px;
		}
	}
}
