.wrapper {
	margin-top: 20px;
	display: flex;
	align-items: flex-start;
	gap: 20px;
	width: 100%;
	.left-side {
		width: 50%;

		.form-item {
			margin-bottom: 20px;
			width: 100%;

			.title {
				font-size: 14px;
				font-weight: 500;
				padding-bottom: 10px;
			}

			.radio-other {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 10px;

				.other-wrapper {
					display: flex;
					align-items: center;
					gap: 5px;

					.other-title {
						font-size: 14px;
						font-weight: 500;
					}
				}
			}
		}

		.map-columns-wrapper {
			.map-columns-title {
				font-size: 14px;
				font-weight: 500;
			}

			.map-column-dropdowns {
				padding-left: 20px;
				padding-top: 10px;
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
				gap: 0 15px;
			}
		}
	}

	.right-side {
		width: 50%;
		.right-side-title {
			font-size: 14px;
			font-weight: 500;
		}

		.copy-paste-table-wrapper {
			height: 560px;
			width: 100%;
		}
	}
}
