.access-denied {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	text-align: center;
	height: calc(100vh - 48px);

	.img {
		width: 350px;
	}

	.message {
		font-size: 16px;
		font-weight: 600;
		margin-top: 20px;
		width: 500px;
		line-height: 20px;
	}
}
