.wrapper {
	.api-token-limits {
		padding: 10px;

		.loading-wrapper,
		.no-data-wrapper,
		.error-wrapper {
			display: grid;
			place-content: center;
			height: calc(100vh - 290px);
		}

		.table-wrapper {
			.table {
				height: calc(100vh - 220px);
				margin-top: 10px;
			}
		}
	}
}

.mail-subject {
	cursor: pointer;
	width: fit-content;
	max-width: 500px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0;
	resize: horizontal;
}

.mail-details-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: self-start;
	margin-bottom: 15px;

	.mail-user-info {
		display: flex;
		align-items: self-start;
		gap: 10px;

		.users-info {
			.sender-name {
				font-size: 14px;
				font-weight: 600;
				padding-bottom: 5px;
			}

			.to,
			.cc,
			.bcc {
				font-size: 12px;
				font-weight: 400;
				padding-top: 3px;
				max-width: 400px;
				/* Set your desired width */
				word-wrap: break-word;

				.title {
					font-weight: 500;
				}
			}
		}
	}

	.date-info {
		font-size: 11px;
	}
}
