.wrapper {
	display: grid;
	place-items: center;
	text-align: center;

	.message {
		font-weight: 600;
		font-size: 16px;
		margin-top: 20px;
		width: 500px;
		line-height: 20px;
	}
}