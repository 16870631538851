.wrapper {
	padding: 0 10px 10px 10px;
	display: flex;
	justify-content: space-between;
	align-items: self-start;
	color: #000000 !important;

	.left-side {
		.title-info {
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			gap: 10px;

			.title {
				font-size: 26px;

				@media (max-width: 768px) {
					font-size: 18px;
				}
			}

			.icon {
				cursor: pointer;
			}
		}

		.info {
			font-size: 16px;
			line-height: 18px;

			@media (max-width: 768px) {
				font-size: 12px;
			}
		}
	}

	.right-side {
		margin-top: -30px;

		@media (max-width: 768px) {
			display: none;
			margin-top: 0;
		}

		.title {
			font-size: 14px;
			padding-bottom: 5px;
		}

		.ul {
			list-style: disc;
			padding-left: 25px;

			.li {
				font-size: 13px;
				line-height: 15px;
			}
		}
	}
}
