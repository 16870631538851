.wrapper {
	display: grid;
	place-items: center;
	text-align: center;

	.img {
		width: 300px;
	}

	.message {
		font-size: 16px;
		font-weight: 600;
		margin-top: 20px;
		width: 600px;
		line-height: 20px;
	}
}