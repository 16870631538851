.wrapper {
	.help-desk-tracker {
		padding: 10px;

		.dropdown-wrapper {
			display: flex;
			align-items: center;
			margin-bottom: 10px;
		}

		.loading-wrapper,
		.no-data-wrapper,
		.error-wrapper {
			display: grid;
			place-content: center;
			height: calc(100vh - 390px);
		}

		.table-wrapper {
			height: calc(100vh - 140px);
			position: relative;
		}
	}
}

.blue-underline {
	color: #0070c0;
	text-decoration: underline;
	cursor: pointer;
	padding-left: 18px;
}
