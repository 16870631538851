.wrapper {
	.count-input {
		display: flex;
		align-items: center;
		border: 1px solid #c8c8c8;
		min-height: 32px;
		width: fit-content;

		.count {
			color: #000000;
			margin-bottom: 0;
			padding: 8px 10px;
			border-right: 1px solid #c8c8c8;
			min-width: 210px;
			font-size: 14px;
		}

		.quick-find {
			border: none;
			box-shadow: none;
		}
	}
}
