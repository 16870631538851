.data-wrapper {
	display: flex;
	gap: 3px;
	align-items: center;

	.edit-icon {
		position: fixed;
		font-size: 14px;
		cursor: pointer;
		margin-right: 3px;
	}

	.data {
		padding-left: 16px;
	}
}

.loading-wrapper,
.no-data-wrapper,
.error-wrapper {
	display: grid;
	place-content: center;
	height: 379px;
}

.greeting-title {
	padding-bottom: 10px;
}

.blue-underline {
	color: #0070c0;
	text-decoration: underline;
	cursor: pointer;
	margin-left: 18px;
	width: 20px;
}

.input-searchButton {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 10px;

	.search-icon {
		font-size: 14px;
	}
}

.table-wrapper {
	height: 379px;
	margin-bottom: 10px;
}

.actions {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
}

.mail-subject {
	cursor: pointer;
	width: fit-content;
	max-width: 500px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0;
	resize: horizontal;
}

.mail-details-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: self-start;
	margin-bottom: 15px;

	.mail-user-info {
		display: flex;
		align-items: self-start;
		gap: 10px;

		.users-info {
			.sender-name {
				font-size: 14px;
				font-weight: 600;
				padding-bottom: 5px;
			}

			.to,
			.cc,
			.bcc {
				font-size: 12px;
				font-weight: 400;
				padding-top: 3px;
				max-width: 400px;
				/* Set your desired width */
				word-wrap: break-word;

				.title {
					font-weight: 500;
				}
			}
		}
	}

	.date-info {
		font-size: 11px;
	}
}
