@import '../../../sass/variables';

.link {
	cursor: pointer;
	color: #2266e3;
	font-weight: 600;
}

.customer-report-wrapper {
	.customer-report {
		padding: 10px;
		background-color: $color-white;

		.dropdown-wrapper {
			display: flex;
			align-items: center;
			margin-bottom: 10px;
		}

		.loading-summary-data {
			display: grid;
			place-content: center;
			height: 120px;
		}

		.no-data-summary-wrapper {
			display: grid;
			place-content: center;

			.no-data-image {
				width: 150px;
			}
		}

		.loading-wrapper,
		.no-data-wrapper,
		.error-wrapper {
			display: grid;
			place-content: center;
			height: calc(100vh - 390px);
		}

		.card-data {
			position: relative;

			.copy-icon {
				position: absolute;
				top: -7px;
				right: 0px;
				font-size: 18px;
				cursor: pointer;
			}

			.summary-data {
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
				gap: 20px 15px;

				@media (max-width: 768px) {
					grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
					gap: 15px 5px;
				}

				.data-wrapper {
					display: flex;
					align-items: center;
					gap: 4px;

					.title {
						font-weight: 600;
						border-bottom: 1px dotted #525252;
						cursor: pointer;
						font-size: 14px;

						@media (max-width: 768px) {
							font-size: 11px;
							border-bottom: none;
						}
					}

					.value {
						font-weight: 400;
						font-size: 14px;

						@media (max-width: 768px) {
							font-size: 12px;
						}
					}
				}
			}
		}

		.table-info {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;
		}

		.table-wrapper {
			height: calc(100vh - 140px);
			position: relative;

			.table-tooltip-notification {
				position: absolute;
				right: 0;
				z-index: 10;
			}
		}
	}
}
