.font-size {
	font-size: 14px;
}

.header-style {
	font-size: 14px !important;
	background-color: #dcdcdc !important;
}

.row-style {
	&:nth-child(odd) {
		background: #f7f0df !important;
	}
}

.wrapper {
	padding-top: 10px;

	.net-components {
		padding: 10px;
	}

	.net-components-dark-background {
		padding: 10px;
		background-color: #383838;
	}
}

.dropdown-wrapper {
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 10px;
	padding: 10px 10px 0 10px;

	.right-side {
		display: flex;
		gap: 5px;
		align-items: center;

		.select-input-wrapper {
			display: flex;
		}

		.search-icon {
			font-size: 14px;
		}
	}

	.left-side {
		display: flex;
		gap: 10px;
	}
}

.loading-wrapper,
.no-data-wrapper,
.error-wrapper {
	display: grid;
	place-content: center;
	height: calc(100vh - 215px);
}

.summary-data {
	display: flex;
	gap: 20px;

	.data-wrapper {
		display: flex;
		align-items: center;
		gap: 4px;

		.title {
			font-weight: 600;
			font-size: 14px;
		}

		.value {
			font-weight: 400;
			font-size: 14px;
		}
	}
}

.table-wrapper {
	.table {
		height: calc(100vh - 220px);
		margin-top: 10px;
		position: relative;

		.table-tooltip-notification {
			position: absolute;
			left: 0;
			top: -25px;
			z-index: 10;
		}
	}
}
