.steps {
	width: 100%;

	:global {
		.ant-steps-item {
			&::before {
				background-color: #001433 !important;
			}

			&::after {
				border-top: 1px solid #001433 !important;
				border-inline-end: 1px solid #001433 !important;
			}
		}

		.ant-steps-item-process {
			.ant-steps-item-icon {
				background-color: #001433;
				border-color: #001433;
			}
		}

		.ant-steps-item-finish {
			.ant-steps-item-container {
				&:hover {
					.ant-steps-item-icon {
						.ant-steps-icon {
							color: #fff !important;
						}
					}
				}
			}

			.ant-steps-item-icon {
				background-color: #001433;
				border-color: #001433;

				.ant-steps-icon {
					color: #fff !important;
				}
			}

			.ant-steps-item-title {
				&::after {
					background-color: #001433;
				}
			}
		}

		.ant-steps-item-active {
			.ant-steps-item-container {
				&:hover {
					.ant-steps-item-icon {
						.ant-steps-icon {
							color: #fff !important;
						}
					}
				}
			}
		}

		.ant-steps-item-container {
			&:hover {
				.ant-steps-item-icon {
					border-color: #001433 !important;

					.ant-steps-icon {
						color: #001433 !important;
					}
				}

				.ant-steps-item-title {
					color: #001433 !important;
				}
			}
		}
	}
}
