.wrapper {
	.checkbox {
		:global {
			.ant-checkbox {
				.ant-checkbox-inner {
					border: 1px solid #d8d8d8;
					border-radius: 2px !important;
				}
			}
		}
	}
}
