.wrapper {
	width: fit-content;
	position: relative;

	.file {
		border: 0.5px solid rgba(0, 0, 0, 0.4);
		border-radius: 2px;
		padding: 7px;
		display: flex;
		align-items: center;
		gap: 6px;
		position: relative;
		max-width: 130px;
		min-width: 130px;

		.file-icon {
			width: 15px;
			height: 15px;
		}

		.file-name {
			// color: $color-text-primary;
			font-weight: 600;
			font-size: 12px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.delete-icon {
		position: absolute;
		right: -7px;
		top: -7px;
		cursor: pointer;
	}
}
