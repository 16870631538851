$color-black: #000000;

$color-white: #ffffff;
$color-bg: rgb(243, 242, 241);
$color-blue: #003f5c;
$color-purple: #7030a0;
$color-magenta: #bc5090;
$color-orange: #f88b64;
$color-yellow: #ffcc42;
$color-red: #c00000;
$color-green: #00684b;
$color-blue: #0070c0;
$color-primary: #7e43f7;
$color-body: #faf9f8;
$color-text: #010007;
$color-text-secondary: #a3a2a7;

$dark-color: #001433;
$microsoft-primary-color: #2266e3;
$light-gray: #f8f8f8;
