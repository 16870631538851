.radio-two {
	&:global.ant-radio-wrapper {
		margin-right: 16px;
		border: 1px solid rgba(0, 0, 0, 0.2);
		border-radius: 4px;
		padding: 5px 10px;
	}

	&:global.ant-radio-wrapper-checked {
		border-color: #001433;
		background-color: #ffffff;
	}

	:global {
		.ant-radio-checked .ant-radio-inner {
			border-color: #001433 !important;
			background-color: #001433;
			box-shadow: none !important;
			outline: none !important;

			&::after {
				background-color: white;
			}
		}
	}
}
