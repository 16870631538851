@import '../../sass/variables';

.help-title {
	font-size: 12px;

	.help-mail {
		color: $color-blue;
		cursor: pointer;
	}
}

.header-admin {
	background-color: $dark-color;
}

.header-ceo {
	background-color: $color-purple;
}

.header-ss {
	background-color: $color-red;
}

.header-sm {
	background-color: $color-blue;
}

.header-sr {
	background-color: $color-green;
}

.header-wrapper {
	height: 48px;
	color: $color-white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px 20px;

	.logo-wrapper {
		display: flex;
		align-items: center;
		gap: 10px;

		.logo {
			width: 30px;
		}

		.hello {
			@media (max-width: 768px) {
				display: none;
			}
		}
	}

	.right-side-wrapper {
		display: flex;
		align-items: center;
		gap: 15px;

		.user-type {
			display: flex;
			align-items: center;
			gap: 10px;

			@media (max-width: 768px) {
				display: none;
			}

			.view-type {
				display: flex;
				align-items: center;
				gap: 5px;

				.user-type {
					margin-bottom: 0;
				}
			}

			.user {
				border-radius: 2px !important;
				font-size: 12px !important;
				font-weight: 500 !important;
				line-height: 20px !important;
				padding: 10px 17px !important;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 24px;
				cursor: pointer;
				transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

				&:hover {
					transform: scale(0.98);
					opacity: 0.9;
				}
			}

			.user-active {
				background-color: #f3f2f1;
				color: $color-black;
			}

			.user-green {
				background-color: $color-green !important;
			}

			.user-blue {
				background-color: $color-blue !important;
			}

			.user-red {
				background-color: $color-red !important;
			}

			.user-purple {
				background-color: $color-purple !important;
			}

			.user-primary {
				background-color: $dark-color !important;
			}
		}

		.help {
			margin-bottom: 0;
			font-size: 20px;
			cursor: pointer;

			@media (max-width: 768px) {
				display: none;
			}
		}

		.user-type-dropdown {
			display: none;
			@media (max-width: 768px) {
				display: block;
			}
		}

		.avatar {
			font-weight: 600;
			cursor: pointer;
		}
	}
}

.userdata-wrapper {
	:global {
		.ant-modal-content {
			border-radius: 3px !important;
			padding: 15px;
			background-color: #f3f2f1;
			box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
				0 9px 28px 8px rgb(0 0 0 / 5%);
		}
	}

	.userdata {
		.header {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.eden {
				text-transform: uppercase;
				font-size: 16px;
				font-weight: 600;
			}

			.sign-out {
				all: unset;
				padding: 10px;
				border-radius: 3px;
				cursor: pointer;
				font-weight: 600;

				&:hover {
					background-color: rgba(0, 0, 0, 0.08);
				}
			}
		}

		.user-info {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 10px;
			margin-top: 20px;

			.big-avatar {
				width: 88px;
				height: 88px;
				border-radius: 50px;
				border: 1px solid #333333;
				display: grid;
				place-items: center;
				font-size: 32px;
				cursor: pointer;
			}

			.info {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 5px;

				.name {
					font-size: 18px;
					font-weight: 700;
				}

				.email {
					font-size: 13px;
				}

				.view-account {
					font-size: 13px;
					color: #0078d6;
					text-decoration: underline;
				}
			}
		}
	}
}
