.input-searchButton {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 10px;

	.search-icon {
		font-size: 14px;
	}
}

.table-wrapper {
	height: 379px;
	margin-bottom: 10px;
}

.loading-wrapper,
.no-data-wrapper,
.error-wrapper {
	display: grid;
	place-content: center;
	height: 379px;
}
