.cd-header {
	background-color: #add8e6;

	&:hover {
		background-color: #add8e6 !important;
	}
}

.cd-header-child {
	background-color: #d9f0f3;

	&:hover {
		background-color: #d9f0f3 !important;
	}
}

.mr-header {
	background-color: #90ee90;

	&:hover {
		background-color: #90ee90 !important;
	}
}

.mr-header-child {
	background-color: #e2fae2;

	&:hover {
		background-color: #e2fae2 !important;
	}
}

.msd-header {
	background-color: #fffa37;

	&:hover {
		background-color: #fffa37 !important;
	}
}

.msd-header-child {
	background-color: #ffffe0;

	&:hover {
		background-color: #ffffe0 !important;
	}
}

.sd-header {
	background-color: #ffb6c1;

	&:hover {
		background-color: #ffb6c1 !important;
	}
}

.sd-header-child {
	background-color: #ffe5e9;

	&:hover {
		background-color: #ffe5e9 !important;
	}
}

.other-header {
	background-color: #d3d3d3;

	&:hover {
		background-color: #d3d3d3 !important;
	}
}

.mpn-cost-book-wrapper {
	.loading-wrapper,
	.no-data-wrapper,
	.error-wrapper {
		display: grid;
		place-content: center;
		height: calc(100vh - 150px);
	}

	.mpn-cost-book {
		padding: 10px;

		.dropdown-actions-wrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;

			.dropdown-wrapper {
				display: flex;
				align-items: center;
			}

			.actions {
				display: flex;
				gap: 10px;
			}
		}

		.table-info {
			margin-bottom: 10px;
		}

		.table-wrapper {
			height: calc(100vh - 150px);
		}
	}
}

.addSalesRep_blue-underline__DI3xf:focus {
	outline: 2px solid red;
}

.mail-subject {
	cursor: pointer;
	width: fit-content;
	max-width: 500px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0;
	resize: horizontal;
}

.mail-details-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: self-start;
	margin-bottom: 15px;

	.mail-user-info {
		display: flex;
		align-items: self-start;
		gap: 10px;

		.users-info {
			.sender-name {
				font-size: 14px;
				font-weight: 600;
				padding-bottom: 5px;
			}

			.to,
			.cc,
			.bcc {
				font-size: 12px;
				font-weight: 400;
				padding-top: 3px;
				max-width: 400px;
				/* Set your desired width */
				word-wrap: break-word;

				.title {
					font-weight: 500;
				}
			}
		}
	}

	.date-info {
		font-size: 11px;
	}
}
