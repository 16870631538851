.wrapper {
	.edit-icon {
		font-size: 18px;
		cursor: pointer;
		display: flex;
		align-items: center;
	}
}

.actions {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
}
