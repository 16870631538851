@import '../../sass/variables';

.button {
	color: $color-white;
	border: none;
	border-radius: 4px !important;
	font-size: 12px !important;
	font-weight: 500 !important;
	line-height: 20px !important;
	padding: 10px 17px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;

	&:hover,
	&:focus {
		transform: scale(0.98);
		color: #fff !important;
		border-color: transparent !important;
	}

	&[disabled],
	&[disabled]:hover {
		opacity: 0.6;
	}
}

.btn-green {
	background-color: $color-green !important;
	color: $color-white !important;
}

.btn-blue {
	background-color: $color-blue !important;
	color: $color-white !important;
}

.btn-yellow {
	background-color: $color-yellow !important;
	color: $color-text !important;
}

.btn-purple {
	background-color: $color-primary !important;
}

.btn-orange {
	background-color: $color-orange !important;
}

.btn-dark {
	background-color: $dark-color !important;
	color: $color-white !important;
}

.btn-red {
	background-color: $color-red !important;
	color: $color-white !important;
}

.border-button {
	color: $color-black;
	border: 1px solid $color-black;
	border-radius: 4px !important;
	font-size: 12px !important;
	font-weight: 500 !important;
	line-height: 20px !important;
	padding: 10px 17px !important;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover,
	&:focus {
		transform: scale(0.98);
		color: $color-black !important;
		border-color: $color-black !important;
	}
}

.button-xs {
	color: $color-white;
	border: none;
	border-radius: 4px !important;
	font-size: 10px !important;
	font-weight: 500 !important;
	line-height: 0px !important;
	padding: 0px 15px !important;

	&:hover,
	&:focus {
		transform: scale(0.98);
		color: #fff !important;
		border-color: transparent !important;
	}
}
