.wrapper {
	.house-accounts {
		padding: 10px;

		.loading-wrapper,
		.no-data-wrapper,
		.error-wrapper {
			display: grid;
			place-content: center;
			height: calc(100vh - 70px);
		}

		.table-wrapper {
			.table {
				height: calc(100vh - 200px);
				max-width: 1000px;
				margin-top: 10px;
			}
		}
	}
	.multiple-tabs {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: calc(100vh - 50px);

		.img {
			width: 300px;
		}

		.message {
			font-size: 16px;
			font-weight: 600;
			margin-top: 20px;

			line-height: 20px;
		}
	}
}
