.wrapper {
	.customer-contacts {
		padding: 10px;

		.dropdown-wrapper {
			display: flex;
			align-items: center;
			margin-bottom: 10px;
		}

		.loading-wrapper,
		.no-data-wrapper,
		.error-wrapper {
			display: grid;
			place-content: center;
			height: calc(100vh - 70px);
		}

		.table-wrapper {
			.table {
				height: calc(100vh - 200px);
				max-width: 1300px;
				margin-top: 10px;
			}
		}
	}
}
