@import '../../../sass/variables';

.loading-wrapper,
.error-wrapper {
	display: grid;
	place-content: center;
	height: calc(100vh - 48px);
}

.gray-cell {
	background-color: #f8f8f8;

	&:hover {
		background-color: #f8f8f8 !important;
	}
}

.blue-header {
	background-color: #dae7f2;

	&:hover {
		background-color: #dae7f2 !important;
	}
}

.pink-header {
	background-color: #f998c0;

	&:hover {
		background-color: #f998c0 !important;
	}
}

.dark-blue-header {
	background-color: #1479c0;
	color: #fff !important;

	&:hover {
		color: #fff !important;
		background-color: #1479c0 !important;
	}
}

.mpn-queue-wrapper {
	.last-report {
		font-size: 12px;
		font-weight: 400;
		font-style: italic;
		padding-right: 10px;
	}

	.mpn-queue {
		padding: 10px;
		background-color: $color-white;

		.dropdown-addrfq-wrapper {
			display: flex;
			gap: 10px;
			align-items: center;
			margin-bottom: 10px;

			.dropdown-wrapper {
				display: flex;
				align-items: center;
				gap: 20px;
				flex-wrap: wrap;
			}
		}

		.no-data-wrapper {
			display: grid;
			place-content: center;
			height: calc(100vh - 155px);
		}

		.table-wrapper {
			height: calc(100vh - 155px);
			position: relative;

			.table-tooltip-notification {
				position: absolute;
				right: 0;
				z-index: 10;
			}
		}
	}
}

.mail-subject {
	cursor: pointer;
	width: fit-content;
	max-width: 500px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0;
	resize: horizontal;
}

.mail-details-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: self-start;
	margin-bottom: 15px;

	.mail-user-info {
		display: flex;
		align-items: self-start;
		gap: 10px;

		.users-info {
			.sender-name {
				font-size: 14px;
				font-weight: 600;
				padding-bottom: 5px;
			}

			.to,
			.cc,
			.bcc {
				font-size: 12px;
				font-weight: 400;
				padding-top: 3px;
				max-width: 400px;
				/* Set your desired width */
				word-wrap: break-word;

				.title {
					font-weight: 500;
				}
			}
		}
	}

	.date-info {
		font-size: 11px;
	}
}
