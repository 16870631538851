.wrapper {
	.my-past-emails {
		padding: 10px;

		.error-wrapper,
		.delay-wrapper {
			display: grid;
			place-content: center;
			height: calc(100vh - 200px);
		}

		.loading-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			height: calc(100vh - 200px);
		}

		.delay-wrapper {
			display: grid;
			place-items: center;
			text-align: center;
			font-size: 16px;
			font-weight: 600;
			margin-top: 20px;
			line-height: 20px;
		}

		.time-left {
			font-size: 16px;
			font-weight: 600;
			margin-top: 20px;
			line-height: 20px;
			max-width: 600px;
			text-align: justify;
		}

		.no-data-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			height: calc(100vh - 200px);

			.search-image {
				width: 350px;
			}

			.no-data {
				max-width: 600px;
				font-size: 16px;
				font-weight: 600;
				margin-top: 20px;
				line-height: 20px;
				text-align: justify;
			}
		}

		.input-checkbox {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			gap: 10px;
			margin: 10px 0;

			.dropdown-input {
				display: flex;
			}
		}

		.table-wrapper {
			height: calc(100vh - 200px);
			margin-top: 10px;
		}
	}
}
