@import '../../sass/variables';

.account-summary-card-wrapper {
	border-radius: 2px;
	background-color: $light-gray;
	padding: 16px;
	margin-bottom: 10px;
	border: 1px solid #d8d8d8;

	@media (max-width: 768px) {
		padding: 15px 5px;
	}
	// min-height: 180px;

	.title {
		font-size: 18px;
		font-weight: 600;
		padding-bottom: 10px;
	}
}
