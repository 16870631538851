.wrapper {
	.linkedin-cleanser {
		padding: 10px;

		.loading-wrapper,
		.no-data-wrapper,
		.error-wrapper {
			display: grid;
			place-content: center;
			height: calc(100vh - 220px);
		}

		.table-wrapper {
			display: flex;
			justify-content: center;

			.table {
				height: calc(100vh - 220px);
				width: 400px;
				margin-top: 10px;
			}
		}
	}
}
