.wrapper {
	.bounceback-tracker {
		padding: 10px;

		.video-container {
			position: relative;
			padding-bottom: 40.25%; /* 16:9 aspect ratio */
			height: 0;
			overflow: hidden;
			max-width: 70%;
			background: #000;
			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 0;
			}
		}
	}
}
