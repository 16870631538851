.wrapper {
	.icons {
		display: flex;
		align-items: center;
		gap: 5px;

		.icon {
			font-size: 14px;
			cursor: pointer;
		}
	}
}

.sourcing-specialist-admin-user-remarks {
	.jodit-container {
		:global {
			.jodit-container {
				ul {
					display: block;
					list-style-type: disc;
					margin-block-start: 1em;
					margin-block-end: 1em;
					margin-inline-start: 0px;
					margin-inline-end: 0px;
					padding-inline-start: 40px;
					unicode-bidi: isolate;
				}

				ol {
					display: block;
					list-style-type: decimal;
					margin-block-start: 1em;
					margin-block-end: 1em;
					margin-inline-start: 0px;
					margin-inline-end: 0px;
					padding-inline-start: 40px;
					unicode-bidi: isolate;
				}

				a:-webkit-any-link {
					color: -webkit-link;
					cursor: pointer;
					text-decoration: underline;
				}
			}

			.jodit_disabled {
				background: #fff;
			}
		}
	}

	.email-body {
		:global {
			.ql-toolbar.ql-snow {
				border: none !important;
			}

			.ql-container.ql-snow {
				border: none !important;
			}
		}
	}
}

.supplier-name {
	margin-bottom: 15px;
}

.loading-wrapper,
.error-message {
	display: grid;
	place-content: center;
	height: 40%;
}

.cards-wrapper {
	margin-top: 20px;

	.card {
		width: 100%;
		height: 100%;
		padding: 10px 0;
		border-bottom: 1px solid #d9d9d9;

		.comment-info {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 14px;

			.comment-user-info {
				display: flex;
				align-items: center;

				.avatar {
					margin-right: 5px;
				}

				.user-name {
					font-weight: 700;
					color: #00000099;

					.add-comment {
						font-weight: 400;
					}
				}

				.date-info {
					font-size: 12px;
					font-weight: 300;
				}
			}
		}

		.remark-text {
			font-size: 14px;
			line-height: 17px;
			color: #0b0b0bb3;

			ol {
				list-style: decimal;
				margin-left: 16px;
			}

			ul {
				list-style: disc;
				margin-left: 18px;
			}
		}
	}
}
