.wrapper {
	.api-token-limits {
		padding: 10px;

		.table-wrapper {
			display: flex;
			justify-content: center;

			.table {
				height: calc(100vh - 220px);
				margin-top: 10px;
				min-width: 800px;
			}
		}
	}
}
